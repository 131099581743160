import { SESSION_EXPIRATION_TIMEOUT } from "@/app/constants";

export default {
  state: {
    sessionTimeoutId: null,
  },
  mutations: {
    RESET_SESSION_TIMEOUT(state, payload) {
      if (state.sessionTimeoutId) {
        window.clearTimeout(state.sessionTimeoutId);
      }
      state.sessionTimeoutId = window.setTimeout(
        payload,
        SESSION_EXPIRATION_TIMEOUT
      );
    },
    CLEAR_SESSION_TIMEOUT(state) {
      if (state.sessionTimeoutId) {
        window.clearTimeout(state.sessionTimeoutId);
      }
      state.sessionTimeoutId = null;
    },
  },
};
