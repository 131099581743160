export const VALIDATION_DELAY = 500;

export const autocomplete = {
  firstName: "given-name",
  lastName: "family-name",
  birthDay: "bday",
  dayOfBirth: "bday-day",
  monthOfBirth: "bday-month",
  yearOfBirth: "bday-year",
  phone: "tel",
  email: "email",
  country: "country-name",
  countryCode: "country",
  state: "address-level1",
  city: "address-level2",
  street: "street-address",
  postalCode: "postal-code",
  creditCard: "cc-number",
  creditCardName: "cc-name",
  creditCardFirstName: "cc-given-name",
  creditCardLastName: "cc-family-name",
  creditCardExpiration: "cc-exp",
  creditCardCvv: "cc-csc"
};
