var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{attrs:{"id":"app"}},[_c('v-main',[_c('transition-group',{attrs:{"name":"page","mode":"out-in"}},[_c('v-container',{key:"container",ref:"wrapper",class:[
          'page-wrapper shine container--fluid mt-4 mb-16',
          {
            'custom-container': _setup.route.name != 'customization-form',
            'fill-height': _setup.route.name == 'maintenance'
          }
        ]},[_c(_setup.AppLoader),_vm._v(" "),_c('router-view'),_vm._v(" "),(_setup.route.name != 'maintenance')?_c(_setup.AppFooter,{staticClass:"mt-10"}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }