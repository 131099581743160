import { isDevMode } from "@/app/system/helper";

export const getSecureFieldsOptions = (isDarkTheme) => ({
  focus: "cardNumber",
  styles: {
    ...(isDarkTheme ? {
      "* input": {
        color: "#fff",
        fontSize: "16px",
        padding: "15px 12px"
      }
    } : {
      "* input": {
        color: "#000",
        fontSize: "16px",
        padding: "15px 12px"
      }
    }),
  },
  debug: isDevMode(),
})
