import Vue from "vue";

export const localStorageHandler = {
  /**
   * Saves the value by the key in the local storage.
   * @param key {string}
   * @param value {string | boolean | number}
   */
  lsSave(key, value) {
    Vue.ls.set(key, value);
  },
  /**
   * Returns the value by the key from the local storage.
   * @param key {string}
   * @return {string|null}
   */
  lsGet(key) {
    return Vue.ls.get(key);
  },
  /**
   * Deletes the value by the key from the local storage.
   * @param key {string}
   */
  lsRemove(key) {
    const namespacedKey = Vue.ls._getLsKey(key);
    Vue.ls.remove(namespacedKey);
  },
};

export default {
  methods: {
    ...localStorageHandler,
  },
};
