import Vue from 'vue'
import ImageKit from 'imagekit-javascript'

export const imageKit = new ImageKit({
  publicKey: process.env.IK_PUBLIC_KEY,
  urlEndpoint: process.env.IK_URL_ENDPOINT,
  authenticationEndpoint: process.env.AUTH_ENDPOINT
})

Vue.prototype.$imagekit = imageKit
