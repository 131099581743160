import SentryClient from './SentryClient';

export function captureRequestException(errorMessage, errorObject, extraData = {}, type = '') {
  SentryClient.captureRequestException({
    errorMessage,
    errorObject,
    extraData
  })
}

export function captureExceptionWithScope(exception, extraKey, extraData, tags = {}, level = 'error') {
  SentryClient.captureExceptionWithScope(exception, {
    extraKey,
    extraData,
    tags
  }, level)
} 
