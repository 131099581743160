import { each, intersection } from "lodash";
import _isEmpty from "lodash/isEmpty";
import { roomPricePerNight } from "./helper";

export const LIMIT_PRICE_MIN = 0;
export const LIMIT_PRICE_MAX = 999999;

export const filterRooms = (filters, rooms, isRoomsType = false) => {
  return rooms.filter((roomObject) => {
    const match = {
      titles: true,
      amenities: true,
      priceRange: true,
    };

    each(filters, (filterData, filterId) => {
      switch (filterId) {
        case "titles":
          match.titles =
            _isEmpty(filterData) ||
            filterData.some(
              (item) => roomObject.room.roomTypeName?.indexOf(item) !== -1
            );
          break;
        case "amenities":
          const roomAmenities = roomObject.room?.amenities;
          const roomAmenitiesCodes = roomAmenities?.map(
            (amenity) => String(amenity?.roomAmenityCode) || ""
          );
          match.amenities =
            !roomAmenities ||
            _isEmpty(filterData) ||
            intersection(filterData, roomAmenitiesCodes).length ===
              filterData.length;
          break;
        case "priceRange":
          const [priceMin = LIMIT_PRICE_MIN, priceMax = LIMIT_PRICE_MAX] =
            filterData;
          const roomPrice = isRoomsType
            ? roomObject.price
            : roomPricePerNight(roomObject);
          match.priceRange = roomPrice >= priceMin && roomPrice <= priceMax;
      }
    });

    return match.titles && match.amenities && match.priceRange;
  });
};
