export const PAYMENT_SOLUTION_PROVIDER_DATATRANS = "Datatrans";
export const DATATRANS_SECURE_3D_SKIPPED = "X";
export const DATATRANS_SECURE_3D_AUTHORIZED = "Y";
export const DATATRANS_SECURE_3D_ACTIVATION_DURING_SHOPPING = "A";

export const DATATRANS_LIB_URL = process.env.DATATRANS_LIB_URL;

export function isDatatrans(gatewayName) {
  return gatewayName === PAYMENT_SOLUTION_PROVIDER_DATATRANS;
}

export function isSecure3DSuccessful(status3d) {
  return [
    DATATRANS_SECURE_3D_AUTHORIZED,
    DATATRANS_SECURE_3D_ACTIVATION_DURING_SHOPPING,
  ].includes(status3d);
}
