import { useCookies } from '@vueuse/integrations/useCookies';
import { ref, watch } from 'vue';

const cmp = 'cookieyes-consent';

export function useConsent() {
  const consent = useCookies([cmp]);
  const { get } = consent;
  const parsedConsent = ref(parseConsentString(get(cmp)));

  watch(() => get(cmp), (newValue) => {
    parsedConsent.value = parseConsentString(newValue);
  });

  return parsedConsent.value;
}

function parseConsentString(consentString) {
  if (!consentString) {
    return {};
  }
  const keyValuePairs = consentString.split(',');
  const consentObject = {};

  for (const pair of keyValuePairs) {
    const [key, value] = pair.split(':');
    consentObject[key] = value === 'yes' ? true : value === 'no' ? false : value;
  }

  return consentObject;
}
