import Validation from "../validator";
import { each } from "lodash";

export const form = {
  methods: {
    submit() {
      this.$_validator.validate();
      if (!this.$_validator.isValid()) {
        return;
      }

      if (this.submitParams !== undefined) {
        each(this.fields, ({ id, val }) => {
          this.submitParams[id] = val;
        });
      }
      this.submitComplete();
    },
  },
  created() {
    // The property in a Vue instance that provides access to the Validator instance.
    this.$_validator = new Validation(this.fields);
  },
};
