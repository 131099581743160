import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from 'vue-i18n-composable';

const navigator = window.navigator;
const navigatorLanguage = (navigator.language || "").substring(0, 2);
export const fallbackLang = process.env.FALLBACK_LANGUAGE;
export const browserLang = navigatorLanguage ? navigatorLanguage : fallbackLang;

Vue.use(VueI18n);

export const i18n = new createI18n({
  locale: "",
  fallbackLocale: fallbackLang || "en",
  silentTranslationWarn: true,
});

export function loadLanguage(lang) {
  if (i18n.locale === lang) {
    return;
  }

  if (i18n.availableLocales.includes(lang)) {
    i18n.locale = lang;
    i18n.lazyloaded = false;
    return;
  }

  import(
    /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    i18n.locale = lang;
    i18n.lazyloaded = true;
  });
}
