import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { setOptions, bootstrap } from 'vue-gtag'
import { isEmpty, wrap } from 'lodash'
import vueRouter from '../../router'
import {
  isDevMode,
  convertPriceStringToFixedPrecision,
  parseJson,
  log,
  getGoogleAnalyticsClientId
} from '../../system/helper'
import { useConsent } from '../../composables/useConsent'

import store from '../../store'

class AnalyticsPlugin {
  constructor() {
    this.trackingPluginsEnabled = false
    this.GTM_CONTAINERS = [process.env.HS_GTM_CONTAINER_ID]
    this.GTAG_IDs = []
    this.EVENTS = {
      SET_SEARCH_PARAMS: {
        gtm: {
          eventName: 'search',
          lookupMethod: this.lookupSetSearchParams
        }
      },
      COMPLETE_BOOKING: {
        gtm: {
          eventName: 'purchase',
          lookupMethod: this.lookupCompleteBooking
        }
      },
      ADD_ORDER_TO_ROOM_STAY: {
        gtm: {
          eventName: 'add_to_cart',
          lookupMethod: this.lookupAddOrderToRoomStay
        }
      },
      REMOVE_LAST_ROOM_STAY_ORDER: {
        ga4: {
          eventName: 'remove_from_cart',
          lookupMethod: this.lookupRemoveLastRoomStayOrder
        }
      }
    }
  }

  enableTrackingPlugins(trackers) {
    if (!isEmpty(trackers)) {
      const { GoogleTagManager, GoogleAnalytics, GoogleGlobalSiteTag } =
        trackers

      if (GoogleGlobalSiteTag?.tagId && GoogleGlobalSiteTag?.routeId) {
        this.GTAG_IDs.push({
          id: GoogleGlobalSiteTag.tagId,
          params: { routeId: GoogleGlobalSiteTag.routeId }
        })
      }

      if (GoogleTagManager?.accountId) {
        this.GTM_CONTAINERS.push(GoogleTagManager.accountId)
      }

      if (GoogleAnalytics?.trackingCodev4) {
        this.GTAG_IDs.push({
          id: GoogleAnalytics.trackingCodev4
        })
      }
    }

    Vue.use(VueGtm, {
      id: [...new Set(this.GTM_CONTAINERS)],
      debug: true,
      enabled: true,
      vueRouter,
      source: `${process.env.HS_GTM_SS_URL}/gtm.js`
    })

    setOptions({
      includes: this.GTAG_IDs,
      config: {
        server_container_url: process.env.HS_GTM_SS_URL
      }
    })

    if (this.shouldUseAnalytics()) {
      bootstrap().then(() => {
        this.trackingPluginsEnabled = true

        store.commit('SET_ANALYTICS_PARAMETER', {
          platform: 'GA4',
          key: 'clientId',
          value: getGoogleAnalyticsClientId()
        })
      })
    }
  }

  useAnalytics() {
    const { consent, analytics } = useConsent()
    return { consent, analytics }
  }

  shouldUseAnalytics() {
    const { consent, analytics } = this.useAnalytics()
    return consent && analytics
  }

  trackGA4Event(eventName, payload) {
    Vue.$gtag.event(eventName, payload)
  }

  trackGTMEvent(eventName, payload) {
    Vue.gtm?.trackEvent({
      event: eventName,
      ...payload,
      send_to: [...new Set(this.GTM_CONTAINERS)]
    })
  }

  // Lookup methods
  lookupSearchParams(state) {
    const {
      promoCode,
      sessionId,
      currencyCode,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants,
      roomStayIndex
    } = state.searchParams
    return {
      hotelId: state.route.params.hotelId,
      roomIndex: roomStayIndex,
      arrival: start,
      departure: end,
      numAdults: nbAdults,
      numChildren: nbChildren,
      numInfants: nbInfants,
      promoCode
    }
  }

  lookupCompleteBooking() {
    return store.getters.ga4Purchase
  }

  lookupAddOrderToRoomStay({ currentRoomStay, servicesInfo }, payload) {
    const {
      order: {
        isPackage,
        hotelPackageName = '',
        hotelPackageId,
        currency,
        totalPrice,
        totalDiscount,
        productId,
        room = {},
        services = []
      }
    } = payload

    const findServiceDetails = (serviceId) =>
      servicesInfo.find((service) => service.serviceId === serviceId) || {}

    const mainItem = {
      ...store.getters.ga4HotelAttributes,
      item_category: isPackage ? 'package' : 'product',
      item_category2: isPackage ? hotelPackageId : productId,
      item_category3: hotelPackageName,
      coupon: currentRoomStay.promotionCode,
      item_variant: room.roomTypeName
    }

    const servicesItems = services.map((service) => {
      const { serviceId, quantity = 1, totalPrice, totalDiscount } = service
      const serviceDetails = findServiceDetails(serviceId)

      return {
        item_id: serviceId,
        item_name: serviceDetails.name || '',
        price: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        discount: convertPriceStringToFixedPrecision(totalDiscount),
        quantity
      }
    })

    return {
      ecommerce: {
        currency,
        value: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        items: [mainItem, ...servicesItems]
      }
    }
  }

  lookupRemoveLastRoomStayOrder(state, payload) {
    // Implement the logic for REMOVE_LAST_ROOM_STAY_ORDER event
  }

  lookupSetSearchParams(state) {
    const {
      promoCode,
      sessionId,
      currencyCode,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants
    } = state.searchParams
    return {
      search_term: `Availability search: ${start} - ${end} for ${nbAdults} adults, ${nbChildren} children, and ${nbInfants} infants.${
        promoCode ? ` Promo code ${promoCode} is applicable.` : ''
      } Session ID: ${sessionId}.`
    }
  }

  trackEvent(eventType, eventConfig, state, payload) {
    const { eventName, lookupMethod } = eventConfig
    const eventPayload = lookupMethod.call(this, state, payload)

    if (eventType === 'ga4') {
      this.trackGA4Event(eventName, eventPayload)
    } else if (eventType === 'gtm') {
      this.trackGTMEvent(eventName, eventPayload)
    }
  }

  handleMutation(mutation, state) {
    if (mutation.type === 'SET_CONFIG' && !this.trackingPluginsEnabled) {
      const { trackers } = mutation.payload
      try {
        const trackingConfig = parseJson(trackers)
        log.info('trackingConfig', trackingConfig)
        this.enableTrackingPlugins(trackingConfig)
      } catch (e) {
        console.error(`Error parsing trackers config: ${e}`)
      }
    }

    try {
      const mutationName = mutation.type.split('/')[0]
      const eventConfigs = this.EVENTS[mutationName]

      if (eventConfigs) {
        Object.entries(eventConfigs).forEach(([eventType, eventConfig]) => {
          this.trackEvent(eventType, eventConfig, state, mutation.payload)
        })
      }
    } catch (e) {
      throw new Error(`Error in trackEvent: ${e}`)
    }
  }
}

export default (store) => {
  const analyticsPlugin = new AnalyticsPlugin()
  store.subscribe((mutation, state) =>
    analyticsPlugin.handleMutation(mutation, state)
  )
}
