import { useCookies } from '@vueuse/integrations/useCookies'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import disableBodyScroll from './utils/disableBodyScroll'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Swal from 'sweetalert2'
import Vue from 'vue'
import chalk from 'chalk'
import { gsap } from 'gsap'
import { i18n } from '../vue-config/i18n'
import { get, chain, isEmpty, uniqBy } from 'lodash'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import chroma from 'chroma-js'
import {
  CURRENCIES,
  ERROR_CODES,
  DATE_FORMAT,
  TRANSITION_TIME_LONG
} from '../constants'

dayjs.extend(duration)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(tz)

export function isDevMode() {
  return (
    process.env.VERCEL_ENV !== 'production' ||
    process.env.NODE_ENV === 'development'
  )
}

export function isProdMode() {
  return (
    process.env.VERCEL_ENV === 'production' ||
    process.env.NODE_ENV === 'production'
  )
}

export function isMaintenanceMode() {
  return process.env.IS_MAINTENANCE && process.env.IS_MAINTENANCE === 'true'
}

export function isMockEnabled() {
  return process.env.API_MOCK_ENABLED === 'true'
}

// Colors
export function opaqueColor(color, opacity) {
  return chroma(color).alpha(opacity)
}

export function lightenColor(color, delta) {
  const chromaColor = chroma(color)
  const modifiedColor = chromaColor
    .luminance(chromaColor.luminance() + delta)
    .hex()
  return modifiedColor
}

// Dates
export function dateDiff(date1, date2, unit = 'd') {
  return dayjs(date1).diff(dayjs(date2), unit)
}

export function dateDiffDays(date1, date2) {
  return Math.abs(dateDiff(date1, date2))
}

export function isDateValid(date) {
  try {
    const parsedDate = dayjs(date)
    if (!parsedDate.isValid()) {
      return false
    }
  } catch (e) {
    return false
  }
  const nativeDate = new Date(date)
  if (isNaN(nativeDate.getTime())) {
    return false
  }
  return true
}

export function isAccommodationDateValid(start, end = null) {
  const startDate = dayjs(start)

  return startDate.isValid() && end
    ? startDate.isAfter(dayjs(end))
    : startDate.isSameOrAfter(dayjs().startOf('day'))
}

export function getLocalizedDate(date, locale, options = {}) {
  try {
    const dayjsDate = dayjs(date)

    if (!dayjsDate.isValid()) {
      console.warn(`Invalid date provided: ${date}`)
    }

    const jsDate = dayjsDate.toDate()

    if (!Number.isFinite(jsDate.getTime())) {
      console.warn(`Date is not finite: ${date}`)
    }

    return new Intl.DateTimeFormat(locale, options).format(jsDate)
  } catch (error) {
    console.error(`Error formatting date: ${date}`, error)
  }
}

export function formatDate(date, format = 'YYYY-MM-DD') {
  return dayjs(date, DATE_FORMAT).format(format)
}

export function dateDifference(checkIn, checkOut) {
  const today = dayjs().startOf('days')
  const checkInDate = dayjs(checkIn)
  const checkOutDate = dayjs(checkOut)
  const remainingDays = dateDiffDays(checkIn, checkOut)

  return checkInDate.isSameOrAfter(today) && checkInDate.isBefore(checkOutDate)
    ? remainingDays
    : 0
}

export function getCheckInDate({ fromCheckInTime, toCheckInTime, timeZone }) {
  const [fromHour, fromMinute] = fromCheckInTime.split(':').map(Number)
  const [toHour, toMinute] = toCheckInTime.split(':').map(Number)

  // Get current time in the specified timezone
  const now = dayjs().tz(timeZone)
  const currentHour = now.hour()
  const currentMinute = now.minute()

  // Convert all times to minutes for easier comparison
  const fromTimeInMinutes = fromHour * 60 + fromMinute
  const toTimeInMinutes = toHour * 60 + toMinute || 24 * 60 // Convert 00:00 to 24:00
  const currentTimeInMinutes = currentHour * 60 + currentMinute

  // If current time is after midnight but before fromTime,
  // then return tomorrow
  if (
    currentTimeInMinutes < fromTimeInMinutes &&
    toTimeInMinutes === 24 * 60 &&
    currentTimeInMinutes > toTimeInMinutes
  ) {
    return now.add(1, 'day').format('YYYY-MM-DD')
  }

  // Otherwise return today as check-in is either current or upcoming today
  return now.format('YYYY-MM-DD')
}

export function latestReservationDate() {
  const advanceDays = parseInt(
    process.env.ADVANCE_RESERVATION_DAYS || '720',
    10
  )
  return dayjs().add(advanceDays, 'day').format('YYYY-MM-DD')
}

export function durationToDate(isoDuration, baseDate = new Date()) {
  return dayjs(baseDate).add(dayjs.duration(isoDuration)).format('YYYY-MM-DD')
}

// get credit card MM/YY from two inputs with values 1-12 and 2020-2030
export function formatCCExpirationDate(month, year) {
  const monthValue = month < 10 ? `0${month}` : month
  const yearValue = year.toString().substr(2, 2)
  return `${monthValue}/${yearValue}`
}

// Locales
export function isLocaleSupported(locale) {
  return process.env.LOCALES.includes(locale)
}

export function parseLocale(lang) {
  const locale = lang.substr(0, 2).toLowerCase()
  return isLocaleSupported(locale) ? locale : ''
}

// Price
export function roomPricePerNight(room) {
  const { nbNights = 1, totalPrice, discount = 0 } = room
  return (totalPrice - discount) / nbNights
}

export function getPrice(array, price) {
  if (!array.length) {
    return 0
  }
  let _helpArray = []
  array.map((o) => _helpArray.push(roomPricePerNight(o)))

  if (price === 'min') {
    return _helpArray.reduce((a, b) => Math.min(a, b))
  }
  if (price === 'max') {
    return _helpArray.reduce((a, b) => Math.max(a, b))
  }
}

export function formatPrice(value, options) {
  if (Number.isNaN(value)) {
    return ''
  }
  const formatter = new Intl.NumberFormat(undefined, {
    currency: 'EUR',
    style: 'currency',
    ...options
  })
  return formatter.format(value)
}

export function convertPriceStringToFixedPrecision(
  priceString,
  decimalPlaces = 2
) {
  const priceFloat = parseFloat(priceString)
  return parseFloat(priceFloat.toFixed(decimalPlaces))
}

// MISC
export function isLocalStorageAccessible() {
  let localStorageAccessible = false

  try {
    localStorage.setItem('__test__', 'test')
    localStorage.removeItem('__test__')
    localStorageAccessible = true
  } catch (error) {
    // localStorage is not accessible
  }

  return localStorageAccessible
}

export function isValidHotelId(id) {
  return /^[A-Za-z0-9]{16}$/.test(id)
}

export function isEmbeddedInIFrame() {
  return window.self !== window.top
}

export const isInIframe = (iframeHostname = '') => {
  let isIframe = false
  try {
    isIframe = window.self !== window.top
    if (iframeHostname) {
      const regex = new RegExp(`^${iframeHostname}\\b`, 'i')
      isIframe = isIframe && regex.test(window.top.location.hostname)
    }
  } catch (e) {
    isIframe = true
  }
  return isIframe
}

export function getMaxLength(array) {
  return array.reduce((a, b) => (a.length > b.length ? a : b)).length
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function sanitizeMarkup(val, type = 'HTML') {
  const sanitizerFunctions = {
    HTML: (val) => DOMPurify.sanitize(val),
    Markdown: (val) => DOMPurify.sanitize(marked.parse(val))
  }

  if (!sanitizerFunctions[type]) {
    console.error(`Unrecognized type: ${type}`)
    return val
  }

  const sanitizer = sanitizerFunctions[type]
  return sanitizer(val)
}

export function animateHeight(val, element) {
  const content = gsap.utils.selector(element)('[data-role="content"]')
  const contentInner = gsap.utils.selector(element)(
    '[data-role="content-inner"]'
  )
  const tt = 0.7
  const tts = tt * 0.8

  switch (val) {
    case 0: {
      let tl = gsap.timeline()
      tl.to(content, { duration: tt, height: 0 }).to(
        contentInner,
        { duration: tts, opacity: 0, stagger: 0.05 },
        0
      )
      break
    }
    case 1: {
      let tl = gsap.timeline()
      tl.to(content, { duration: tt, height: 'auto' }).to(
        contentInner,
        { duration: tts, opacity: 1, stagger: 0.05 },
        0
      )
      break
    }
    default: {
      let tl = gsap.timeline()
      tl.set(content, { height: 0 })
    }
  }
}

export function toggleModal(val, scrollSelector, delay = TRANSITION_TIME_LONG) {
  const combinedSelector = scrollSelector
    ? `${scrollSelector} .scroll-container`
    : null
  const scrollContainer = document.querySelector(combinedSelector)

  const body = document.querySelector('body')
  const html = document.querySelector('html')
  if (val === 1) {
    html.classList.add('_has-modal')
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      body.style.paddingRight = scrollBarMeasure() + 'px'
    } else if (scrollContainer) {
      disableBodyScroll(true, scrollContainer)
    }
  }
  if (val === 0) {
    setTimeout(() => {
      html.classList.remove('_has-modal')
      body.style.paddingRight = ''
      if (scrollContainer) {
        disableBodyScroll(false, scrollContainer)
      }
    }, delay)
  }
}

function scrollBarMeasure() {
  let scrollDiv = document.createElement('div')
  scrollDiv.className = 'scrollbar-measure'
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export function forceRemoveModalClasses() {
  let body = document.querySelector('body')
  body.classList.remove('_has-modal')
  body.style.paddingRight = ''
  disableBodyScroll(false)
}

export function getCSSVariable(varName) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(varName)
    .trim()
}

export function normalizeQueryParams(params) {
  for (const key in params) {
    let num = parseInt(params[key])

    if (params[key] !== '') {
      switch (key) {
        case 'checkIn':
        case 'checkOut': {
          const today = dayjs().format('YYYY-MM-DD')
          const tomorrow = dayjs().add(1, 'days').format('YYYY-MM-DD')
          if (
            !isAccommodationDateValid ||
            dateDifference(params.checkIn, params.checkOut) === 0
          ) {
            params.checkIn = today
            params.checkOut = tomorrow
          }
          break
        }
        case 'lang':
          params[key] = isLocaleSupported(params[key]) ? params[key] : 'en'
          break
        case 'currency': {
          const allCurrencies = Object.values(CURRENCIES).flat()
          params[key] = allCurrencies.includes(params[key])
            ? params[key]
            : 'EUR'
          break
        }
        default: {
          break
        }
      }
    } else {
      delete params[key]
    }
  }
  return params
}

export function showAlertPopup(error, severity = 'error') {
  const title = i18n.t('App#Error Oops!')
  const defaultOptions = {
    title,
    icon: severity
  }

  if (typeof error === 'string') {
    return Swal.fire({
      ...defaultOptions,
      text: error
    })
  }

  return Swal.fire({
    ...defaultOptions,
    ...error
  })
}

function activeSlideEvent() {
  let slides = Array.from(this.querySelectorAll('.tns-item'))
  slides.forEach((el) => {
    if (el.classList.contains('tns-slide-active')) {
      el.classList.add('is-active')
    } else {
      el.classList.remove('is-active')
    }
  })
}

export function checkActiveSlide() {
  let sliders = Array.from(document.querySelectorAll('.tns-slider'))
  sliders.forEach((el) => {
    el.removeEventListener('transitionend', activeSlideEvent.bind(el), false)
    if (el.classList.contains('tns-slide-active')) {
      el.classList.add('is-active')
    } else {
      el.classList.remove('is-active')
    }
  })
}

export function resizeIframe() {
  if (self !== top) {
    let iframePrevHeight
    const iframeResize = () => {
      const el = document.getElementById('app'),
        height = Math.max(el.clientHeight, el.offsetHeight) + 5
      if (iframePrevHeight !== height) {
        iframePrevHeight = height
        parent.postMessage(height, '*')
      }
    }
    iframeResize()
    setInterval(iframeResize, 250)
  }
}

export function getResolution({ xl, lg, md, sm, xs }) {
  const resolutions = {
    Desktop: xl || lg,
    Tablet: md || sm,
    Mobile: xs
  }
  return Object.entries(resolutions).find(([_, v]) => v)[0]
}

export function getImageKitUrls(images, breakpoint, useLegacyHosting = false) {
  const imageSizes = {
    Mobile: { width: 600, height: 300 },
    Tablet: { width: 1264, height: 480 },
    Desktop: { width: 1920, height: 800 }
  }
  const srcsetWidths = Object.values(imageSizes).map((size) => size.width)
  const { width, height } = imageSizes[breakpoint] || imageSizes.Desktop
  const baseUrl = useLegacyHosting
    ? 'https://crs-tourisoft.s3.eu-west-1.amazonaws.com'
    : 'https://multimedia.hotel-spider.com'

  // Simplified sizes attribute
  const sizes = '(max-width: 600px) 100vw, (max-width: 1264px) 75vw, 50vw'

  return images
    .sort((a, b) => a.sort - b.sort)
    .map(({ imageId, imagePath, alt, createDateTime }) => {
      const baseImageUrl = `${baseUrl}${imagePath}?tr=w-${width},pr-true,fo-auto`
      const lazyImageUrl = `${baseUrl}${imagePath}?tr=w-1,h-1:w-450`

      const srcset = srcsetWidths
        .map((w) => `${baseUrl}${imagePath}?tr=w-${w},pr-true,fo-auto ${w}w`)
        .join(', ')

      return {
        imageId: imageId ?? createDateTime,
        src: baseImageUrl,
        lazySrc: lazyImageUrl,
        srcset,
        sizes,
        width,
        height,
        alt: alt ?? 'Carousel room image'
      }
    })
}

export function filterImagesByDimensionCategory(images, dimension) {
  return uniqBy(
    chain(images)
      .sortBy('sort')
      .filter((image) => image.dimensionCategory === dimension)
      .value(),
    'imageId'
  )
}

export function parseJson(jsonString) {
  if (!jsonString || jsonString.trim() === '') {
    return null
  }

  try {
    return JSON.parse(jsonString)
  } catch (error) {
    console.error('Error parsing JSON', error)
    return null
  }
}

// Labels and error messages
export function getInternalCodeFromResponse(response) {
  return get(response, 'body.internalErrorCode', null)
}

export function getErrorDescription(code) {
  return i18n.t(ERROR_CODES[code]) || 'Internal server error.'
}

export function getTranslatedHotelContacts(params) {
  if (params.phoneNumber != 'N/A' && params.emailAddress != 'N/A') {
    return i18n.t(
      'App#Error#Contact#PhoneAndEmail You can contact us by phone at {phoneNumber} or by mail at {emailAddress}',
      params
    )
  } else if (params.phoneNumber != 'N/A') {
    return i18n.t(
      'App#Error#Contact#Phone You can contact us by phone at {phoneNumber}',
      { phoneNumber: params.phoneNumber }
    )
  } else if (params.emailAddress != 'N/A') {
    return i18n.t(
      'App#Error#Contact#Email You can contact us by mail at {emailAddress}',
      { emailAddress: params.emailAddress }
    )
  } else {
    return ''
  }
}

export function getErrorMessageWithTimestamp({
  reservationId = '',
  sessionId = '',
  internalErrorCode = null,
  errorDescription,
  errorCodeParams = {},
  i18nParams = {}
}) {
  const error = errorDescription
    ? i18n.t(errorDescription)
    : getErrorDescription(internalErrorCode)
  const contacts = getTranslatedHotelContacts(i18nParams)
  const timestamp = getTimestampForError({
    hotelId: Vue.prototype.$hotelId,
    reservationId,
    sessionId,
    internalErrorCode,
    extraParams: errorCodeParams
  })

  return `
    ${error}
    ${contacts ? `\n\n${contacts}` : ''}
    ${timestamp}
  `
}

export function getTimestampForError({
  hotelId,
  reservationId = '',
  sessionId = '',
  internalErrorCode = null,
  extraParams
}) {
  const errorTime = new Date().toUTCString()
  const errorCode = getErrorCode({
    hotelId,
    sessionId,
    reservationId,
    extraParams
  })
  const internalErrorCodeMsg = internalErrorCode ? `#${internalErrorCode}` : ''

  return `\n
    Time: ${errorTime}
    Error code: ${errorCode}${internalErrorCodeMsg}`
}

function getErrorCode({
  hotelId,
  sessionId = '',
  reservationId = '',
  extraParams = {}
}) {
  let errorCodeMessage = hotelId
  /**
   * Book reservation flow
   */
  if (sessionId) {
    return `${errorCodeMessage}#${sessionId}`
  }
  /**
   * Modify reservation flow
   */
  if (reservationId) {
    errorCodeMessage += `#${reservationId}`
  }
  if (!isEmpty(extraParams)) {
    for (const param in extraParams) {
      errorCodeMessage += `#${extraParams[param]}`
    }
  }
  return errorCodeMessage
}

export const log = {
  info: (msg) => {
    chalk.blue(msg)
  }
}

export function replaceQueryParameter(paramName, newValue) {
  const url = new URL(window.location.href)
  const searchParams = url.searchParams

  if (searchParams.has(paramName)) {
    searchParams.set(paramName, newValue)
  } else {
    searchParams.append(paramName, newValue)
  }

  const newUrl = url.toString()
  window.history.replaceState({}, '', newUrl)
}

export function getGoogleAnalyticsClientId() {
  const cookies = useCookies()
  const gaCookie = cookies.get('_ga')

  if (gaCookie) {
    const parts = gaCookie.split('.')
    if (parts.length > 2) {
      return `${parts[2]}.${parts[3]}`
    }
  }

  return null
}
