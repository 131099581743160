import { toggleModal, capitalizeFirstLetter } from "../helper";

const onResizeListener = (element) => {
  element.style.height = window.innerHeight + "px";
};

export default {
  data() {
    return {
      modalProps: {
        timeout: null,
      },
    };
  },
  methods: {
    $_toggleModal(modal, ref) {
      const modalSelector = `.no-scroll_${modal}`;
      const el = ref.$el || ref;
      const onResize = () => {
        onResizeListener(el);
      };

      const isActive = el.classList.contains("is-active");
      if (!isActive) {
        el.classList.add("is-active");
        el.style.height = window.innerHeight + "px";
        window.addEventListener("resize", onResize);
        toggleModal(1, modalSelector);
        this.$bus.$emit(`open-${modal}`);
      } else {
        el.classList.remove("is-active");
        let apply = `apply${capitalizeFirstLetter(modal)}`;
        this.$bus.$emit(apply);
        window.removeEventListener("resize", onResize);
        toggleModal(0, modalSelector);
      }
    },
  },
};
