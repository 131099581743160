import { each, forOwn, isEqual } from 'lodash'
import {
  getPrice,
  parseJson,
  getCheckInDate,
  latestReservationDate
} from '../system/helper'

export default {
  SET_SEARCH_PARAMS(state, data) {
    state.searchParams = data
  },
  SET_CONFIG(state, data) {
    state.addressRequired = data.addressRequired
    state.hotelHomepage = data.hotelHomepage
    state.tenantId = data.tenantId
    state.hotelCurrency = data.currency
    state.invoicingCurrency = data.invoicingCurrency
    state.test = data.test
    state.subscription = data.subscription
    state.useOffers = data.useOffers ?? true
    state.guestsBounds.adults.min = data.minAdults
    state.guestsBounds.adults.max = data.maxAdults
    state.guestsBounds.children.min = data.minChildren
    state.guestsBounds.children.max = data.maxChildren
    state.guestsBounds.infants.min = data.minInfants
    state.guestsBounds.infants.max = data.maxInfants
    state.defaultSorting = data.defaultSorting || 'popularity' // added ' || 'popularity' ' for condition if we don't have any defaultSorting in config
    if (data.trackers) {
      state.trackers = {
        ...state.trackers,
        ...parseJson(data.trackers)
      }
    }
    state.configLoaded = true
  },
  SET_CHANNEL_PARAMETERS(state, channelParameters) {
    state.channelParameters = { ...channelParameters }
  },
  SET_CURRENCY(state, data) {
    state.currency = data
  },
  SET_PRIVACY_POLICY(state, data) {
    state.privacyPolicy = data
  },
  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_ALLOW_THANKS(state, data) {
    state.allowThanks = data
  },
  SET_PAYMENT_TYPE(state, data) {
    state.paymentType = data
  },
  SET_RESERVATION_LOADING(state, data) {
    state.reservationLoading = data
  },
  SET_INLINE_BOOKING_REQUEST_ERROR(state, data) {
    state.inlineBookRequestError = data
  },
  SET_CANCELLATION_LOADING(state, data) {
    state.cancellationLoading = data
  },
  SET_ALLOW_CANCELLATION_CONFIRMATION(state, data) {
    state.allowCancellationConfirm = data
  },
  UPDATE_CANCELLED_ORDER_INFO(state, data) {
    state.orderCancelledInfo = data
  },
  UPDATE_CURRENT_ROOM_STAY({ currentRoomStay }, data) {
    each(data, (value, key) => {
      currentRoomStay[key] = value
    })
  },
  UPDATE_ORDER_INFO({ orderInfo }, data) {
    each(data, (value, key) => {
      orderInfo[key] = value
    })
  },
  ADD_TO_INFO(state, data) {
    state.orderInfo[data.field] = data.item
  },
  SET_LANGUAGE(state, data) {
    state.language = data
  },
  SET_LANGUAGES(state, data) {
    !isEqual(state.languages, data) && (state.languages = data)
  },
  SET_CURRENCIES(state, data) {
    !isEqual(state.currencies, data) && (state.currencies = data)
  },
  SET_LOGGED_STATUS(state, data) {
    state.logged = data
  },
  SET_ROOMS(state, items) {
    let rooms = [],
      roomsArray,
      productsArray

    const productsAvailable = []
    const roomAvailabilities = {}
    if (items == null || items?.length === 0) {
      state.roomsBeforeRender = rooms
      state.rooms = rooms
      state.filteredRooms = rooms
    } else {
      roomsArray = state.descriptiveInfoLoaded
        ? state.descriptiveInfo.facilityInfo?.guestRooms
        : []
      productsArray = state.products

      items.forEach((item) => {
        roomAvailabilities[item.guestRoomId] = item.availability

        const room = {
          ...item,
          productId: item.guestRoomId,
          products: item.products,
          packages: item.packages,
          room: roomsArray?.find((room) => room.id === item.guestRoomId)
        }

        item.products?.forEach((productFromRoom) => {
          const productDefinition = productsArray.find(
            (product) =>
              product?.roomTypes?.[0].roomTypeCode === item.guestRoomId &&
              product?.ratePlans?.[0].ratePlanCode ===
                productFromRoom.ratePlanId
          )

          productFromRoom.guestRoomId = item.guestRoomId
          productFromRoom.room = room
          productFromRoom.productId = productDefinition?.ID

          const rate = productDefinition?.ratePlans?.[0]

          if (rate) {
            productFromRoom.rate = rate
            productFromRoom.hasServices = productDefinition.hasServices
            productFromRoom.nbNights =
              state.bookingRequest.bookingRequest.nbNights || 1
            productsAvailable.push(productFromRoom)
          }
        })

        rooms.push(room)
      })

      state.roomsBeforeRender = rooms
      state.rooms = rooms
      state.filteredRooms = rooms
      state.productsAvailable = productsAvailable

      state.serverRoomAvailabilities = roomAvailabilities

      const _minPrice = Math.floor(getPrice(state.productsAvailable, 'min'))
      const _maxPrice = Math.ceil(getPrice(state.productsAvailable, 'max'))
      state.defaultPriceRange = [_minPrice, _maxPrice]
    }
  },
  SET_CALENDAR(state, data) {
    // state.calendarData = data;
    const { specialDays, disabledDays } = state.calendarData
    forOwn(data, ({ bookable, currencyCode, price }, date) => {
      if (bookable) {
        specialDays[date] = `${currencyCode} ${price}`
      } else {
        disabledDays.push(date)
      }
    })
  },
  SET_DESCRIPTIVE_INFO(state, data) {
    if (data.timeZone) {
      data.latestCheckInTimeDate = getCheckInDate(data)
      data.latestReservationTimeDate = latestReservationDate()
    }
    state.descriptiveInfo = { ...state.descriptiveInfo, ...data }
  },
  SET_PROMOTIONS_INFO(state, data) {
    state.hasPromotions = data.anyActive
  },
  SET_DESCRIPTIVE_INFO_LOADED(state, data) {
    state.descriptiveInfoLoaded = data
  },
  SET_PRODUCTS(state, data) {
    state.products = data
    state.productsLoaded = true
  },
  SET_HOTEL_PACKAGES(state, data) {
    state.hotelPackages = data
  },
  SET_HOTEL_PACKAGES_AVAILABLE(state, data) {
    if (!data.length) {
      state.hotelPackagesAvailable = []
      return
    }

    const guestRooms = state.descriptiveInfo.facilityInfo?.guestRooms ?? []

    state.hotelPackagesAvailable = data.map((item) => {
      const room = guestRooms.find((room) => room.id === item.guestRoomId)
      const roomInfo = { ...item, room }

      return {
        ...roomInfo,
        packages: item.packages.map((hotelPackage) => ({
          ...hotelPackage,
          room: roomInfo
        }))
      }
    })
  },
  SET_SERVICES(state, data) {
    const sortedData = data.sort((a, b) => {
      if (a.featured && !b.featured) {
        return -1
      } else if (!a.featured && b.featured) {
        return 1
      } else {
        return a.sort - b.sort
      }
    })
    state.servicesInfo = sortedData
  },
  SET_SERVICES_LOADED(state, data) {
    state.servicesLoaded = data
  },
  SET_FILTERED_ROOMS(state, rooms) {
    state.filteredRooms = rooms
  },
  SET_UPGRADES(state, upgrades) {
    state.orderInfo.upgrades = upgrades
  },
  SET_UPSELL(state, upsell) {
    state.orderInfo.upsell = upsell
  },
  SET_USER(state, user) {
    state.user = Object.assign({}, user)
  },
  SET_SORTER_NAME(state, sorterName) {
    state.sorterName = sorterName
  },
  SET_ROOM_FILTERS(state, filters) {
    state.roomFilters = { ...state.roomFilters, ...filters }
  },
  RESET_ROOM_FILTERS(state) {
    state.roomFilters = {
      titles: [],
      amenities: [],
      priceRange: []
    }
  },
  WINDOW_RESIZE(state, size) {
    state.windowWidth = size
  },
  SET_CHANNEL_ID(state, channelId) {
    state.channelId = channelId
  },
  SET_AVAILABILITIES_LOADED(state, availabilitiesLoaded) {
    state.availabilitiesLoaded = availabilitiesLoaded
  },
  UPDATE_CUSTOMIZATION_CONFIG(state, data) {
    state.customization = { ...state.customization, ...data }
  },
  CUSTOMIZATION_SENDING(state) {
    state.customizationFormSending = !state.customizationFormSending
  },
  SET_ANALYTICS_PARAMETER(state, { platform, key, value }) {
    if (state.analytics[platform]) {
      state.analytics[platform][key] = value
    }
  },
  UPDATE_CONSENT_STATE(state, { platform, consentType, consentValue }) {
    if (state.analytics[platform] && state.analytics[platform].consentState) {
      state.analytics[platform].consentState[consentType] = consentValue
    }
  }
}
