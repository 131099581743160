import { mapGetters, mapActions, mapMutations } from 'vuex'
import { formatDate } from '@/app/system/helper'
import { isEmpty } from 'lodash'

export default {
  computed: {
    ...mapGetters(['bookingRequest', 'currentRoomStay'])
  },
  methods: {
    ...mapActions(['getCancellationPolicies', 'replaceRoute']),
    ...mapMutations(['ADD_TO_INFO']),
    roomStayParams(roomStay = {}) {
      const room = isEmpty(roomStay) ? this.currentRoomStay : roomStay
      const { guestRoomId: roomId, ratePlanId, startDate, endDate } = room
      return {
        sessionId: this.bookingRequest.sessionId,
        start: formatDate(startDate),
        end: formatDate(endDate),
        roomId: room.room?.guestRoomId ?? roomId,
        ratePlanId
      }
    },
    async getCurrentRoomStayCancellationPolicies() {
      const params = this.roomStayParams()

      await this.getCancellationPolicies(params).then((res) => {
        if (res) {
          this.ADD_TO_INFO({ field: 'penalties', item: res })
        }
      })
    },

    async getRoomStayCancellationPolicies(roomStay) {
      const params = this.roomStayParams(roomStay)
      return await this.getCancellationPolicies(params)
    }
  }
}
