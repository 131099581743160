import { inRange } from 'lodash'
import isAlphanumeric from 'validator/es/lib/isAlphanumeric'
import isLength from 'validator/es/lib/isLength'
import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export function isRequiredValidator(value: string): boolean | string {
  return (
    !isEmpty((value ?? '').trim()) || 'Form#Validation The field is required'
  )
}

export function isEmailValidator(value: string): boolean | string {
  return (
    isEmail(value) ||
    'Form#Validation Please enter correct email, e.g johndoe@gmail.com'
  )
}

export function phoneValidator(value: string): boolean | string {
  return (
    isLength(value, { min: 1, max: 32 }) ||
    'Form#Validation The entered phone number is not correct'
  )
}

export function reservationIdValidator(value: string): boolean | string {
  const pattern = /^SB4-[a-zA-Z0-9]{10}$/
  return (
    Boolean(value.match(pattern)) ||
    'Form#Validation Reservation number format must be SB4-XXXXXXXX'
  )
}

export function passwordValidator(value: string): boolean | string {
  return (
    !isLength(value, { min: 6, max: undefined }) ||
    !isAlphanumeric(value) ||
    !value.match('[0-9]') ||
    'Form#Validation Password must be 6 or more characters and contain at least 1 number'
  )
}

export function postalCodeValidator(value: string): boolean | string {
  return (
    isLength(value, { min: undefined, max: 16 }) ||
    'Form#Validation Please enter correct postal code, e.g 12345'
  )
}

export function ccExpirationValidator(value: string): boolean | string {
  const monthValue = value.slice(0, 2)
  const yearValue = value.slice(3)
  const currentYear = dayjs().format('YY')

  return (
    /* months in range from 01 through 12 */
    (
      /0[1-9]|1[0-2]/.test(monthValue) &&
      /**
       * The validator will consider the year value in range from
       * 2010 through the current year as valid. The past years are handled by
       * the other validator below. This is needed to keep the logic consistent.
       */
      inRange(Number(yearValue), 10, Number(currentYear) + 9) &&
      /^\d\d\/\d\d$/.test(value)
    ) || 'Form#Validation Please enter correct 2-digit month/year format: MM/YY'
  )
}

export function isCCExpiredValidator(value: string): boolean | string {
  return (
    !dayjs(value, 'MM/YY', true).isBefore(dayjs(), 'month') ||
    'Form#Validation Expiration date of a card cannot be less than the current date.'
  )
}
