import { useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints({
  mobile: 600,
  tablet: 1264
})

export const isDesktop = breakpoints.greaterOrEqual('tablet')
export const isMobile = breakpoints.smaller('mobile')
export const isTablet = breakpoints.between('mobile', 'tablet')

export default breakpoints
