/**
 * Prevent body scroll and overscroll.
 * Tested on mac, iOS chrome / Safari, Android Chrome.
 *
 * Based on: https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
 *           https://stackoverflow.com/a/41601290
 *
 * Use in combination with:
 * html, body {overflow: hidden;}
 *
 * and: -webkit-overflow-scrolling: touch; for the element that should scroll.
 *
 * disableBodyScroll(true, '.i-can-scroll');
 */

/**
 * Private variables
 */
let _selector = false,
  _element = false,
  _clientY;

/**
 * Polyfills for Element.matches and Element.closest
 */
if (!Element.prototype.matches) {
  (function (e) {
    var matches =
      e.matches ||
      e.matchesSelector ||
      e.webkitMatchesSelector ||
      e.mozMatchesSelector ||
      e.msMatchesSelector ||
      e.oMatchesSelector;
    !matches
      ? (e.matches = e.matchesSelector =
          function matches(selector) {
            var matches = document.querySelectorAll(selector);
            var th = this;
            return Array.prototype.some.call(matches, function (e) {
              return e === th;
            });
          })
      : (e.matches = e.matchesSelector = matches);
  })(Element.prototype);
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
}

/**
 * Prevent default unless within _selector
 *
 * @param  event object event
 * @return void
 */
const preventBodyScroll = function (event) {
  if (
    (false === _element || !event.target.closest(_selector)) &&
    event.cancelable
  ) {
    event.preventDefault();
  }
};

/**
 * Cache the clientY co-ordinates for
 * comparison
 *
 * @param  event object event
 * @return void
 */
const captureClientY = function (event) {
  // only respond to a single touch
  if (event.targetTouches.length === 1) {
    _clientY = event.targetTouches[0].clientY;
  }
};

/**
 * Detect whether the element is at the top
 * or the bottom of their scroll and prevent
 * the user from scrolling beyond
 *
 * @param  event object event
 * @return void
 */
const preventOverscroll = function (event) {
  // only respond to a single touch
  if (event.targetTouches.length !== 1 || !event.cancelable) {
    return;
  }

  const clientY = event.targetTouches[0].clientY - _clientY;

  // The element at the top of its scroll,
  // and the user scrolls down
  if (_element.scrollTop === 0 && clientY > 0) {
    event.preventDefault();
  }

  // The element at the bottom of its scroll,
  // and the user scrolls up
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
  if (
    _element.scrollHeight - _element.scrollTop <= _element.clientHeight &&
    clientY < 0
  ) {
    event.preventDefault();
  }
};

/**
 * Disable body scroll. Scrolling with the selector is
 * allowed if a selector is porvided.
 *
 * @param allow {boolean} allow
 * @param selector {string?} selector Selector to element to change scroll permission
 * @return void
 */
export default function (allow, selector) {
  if (selector !== undefined) {
    _selector = selector;
    _element = document.querySelector(selector);
  }

  const defaultParameters = { passive: false };
  if (true === allow) {
    if (_element) {
      _element.addEventListener("touchstart", captureClientY, false);
      _element.addEventListener("touchmove", preventOverscroll, false);
    }

    document.body.addEventListener(
      "touchmove",
      preventBodyScroll,
      defaultParameters
    );
  } else {
    if (_element) {
      _element.removeEventListener("touchstart", captureClientY, false);
      _element.removeEventListener("touchmove", preventOverscroll, false);
    }

    document.body.removeEventListener(
      "touchmove",
      preventBodyScroll,
      defaultParameters
    );
  }
}
