import { sortBy } from 'lodash'
import { roomPricePerNight } from './helper'

export const LOCAL_STORAGE_KEY_SORT_BY = 'sort-by'

export const sortProducts = (sortName, rooms) => {
  const sortType = {
    popularity: () =>
      sortBy(rooms, (item) => {
        item.room.products = sortBy(
          item.room.products,
          (product) => -product.popularity
        )
        return -item.popularity
      }),
    pricesUp: () =>
      sortBy(rooms, (item) => {
        item.room.products = sortBy(item.room.products, (product) =>
          roomPricePerNight(product)
        )
        return item.price
      }),
    pricesDown: () =>
      sortBy(rooms, (item) => {
        item.room.products = sortBy(
          item.room.products,
          (product) => -roomPricePerNight(product)
        )
        return -item.price
      }),
    roomName: () =>
      sortBy(rooms, (item) => {
        item.room.products = sortBy(
          item.room.products,
          (product) => product.rate?.ratePlanName ?? product.room.roomTypeName
        )
        return item.title
      }),
    default: () => sortProducts('popularity', rooms)
  }

  return (sortType[sortName] || sortType['default'])()
}
