import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition && window.location === window.parent.location) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
