import Vue from 'vue'
import { each } from 'lodash'
import store from '../store'
import SentryClient from './SentryClient'
import { parseJson } from './helper'

const baseUrl = process.env.CRS_ENDPOINT
const translationsURL = process.env.TRANSLATIONS_ENDPOINT

const getHotelId = () => Vue.prototype.$hotelId
const apiKeyMapping = parseJson(process.env.CHANNEL_KEYS)

const actionTemplates = {
  config: `${baseUrl}/config/{hotelId}`,
  customization: `${baseUrl}/config/customization/{hotelId}`,
  calendar: `${baseUrl}/calendar/{hotelId}`,
  currencies: `${baseUrl}/currencies`,
  availabilities: `${baseUrl}/availabilities/{hotelId}`,
  privacyPolicy: `${baseUrl}/config/privacy-policy/{language}/{hotelId}`,
  privacyPolicyDefault: `${baseUrl}/config/privacy-policy/{language}/default`,

  descriptiveInfo: `${baseUrl}/descriptive-info/{language}/{hotelId}`,
  products: `${baseUrl}/products/{language}/{hotelId}`,
  services: `${baseUrl}/services/{language}/{hotelId}`,
  hotelPackages: `${baseUrl}/hotel-packages/{language}/{hotelId}`,
  bundles: `${baseUrl}/bundles/{hotelId}/{sessionId}/{bundleId}`,

  cancellationPolicies: `${baseUrl}/cancel-penalties/{language}/{hotelId}`,

  reservation: `${baseUrl}/reservations/{hotelId}/{language}/{reservationId}`,
  reservationsBook: `${baseUrl}/reservations/{hotelId}/actions/book`,
  reservationsPay: `${baseUrl}/reservations/{hotelId}/actions/pay`,
  reservationTaxes: `${baseUrl}/reservations/{hotelId}/actions/getTaxes`,
  reservationsModify: `${baseUrl}/reservations/{hotelId}/actions/modify`,
  reservationsCancel: `${baseUrl}/reservations/{hotelId}/actions/cancel`,
  roomStayCancel: `${baseUrl}/roomstays/{hotelId}/{reservationId}/{roomStayIndex}`,

  trackingTrivago: `${baseUrl}/meta/trivago/tracking/booking`,
  trackingTripAdvisor: `${baseUrl}/meta/tripadvisor/tracking`,

  promotionsInfo: `${baseUrl}/promotions/{hotelId}`,

  threeDsAuthentication: `${baseUrl}/payment/{hotelId}/threeDsAuthentication`,

  allLanguages: `${translationsURL}/HS/languages/list.json`,
  recommendedLanguages: `${translationsURL}/HS/languages/recommended.json`,
  countriesTranslations: `${translationsURL}/out/ISO3166-1/2/{language}.json`,
  currenciesTranslations: `${translationsURL}/out/ISO4217/{language}.json`
}

/**
 *
 * @param action { string }
 * @param params { Object? }
 * @return { string }
 */
const resolveUrlParams = (action, params = {}) => {
  const language = store.state.language || 'en'
  const hotelId = getHotelId()

  params = {
    language,
    hotelId,
    ...params
  }
  let url = actionTemplates[action]
  each(params, (value, key) => (url = url.replace(`{${key}}`, value)))

  return url
}

/**
 * @param url { string }
 * @param params { { method?: string, queryParams?: Object, body?: Object, headers: Object? }? }
 * @return { Promise<*> }
 */
const request = (url, params = {}) => {
  const { method = 'GET', queryParams = {}, body = {}, headers = {} } = params

  return Vue.http({
    url,
    method,
    params: queryParams,
    headers,
    body
  })
}

/**
 * @param action { string }
 * @param params { { method?: 'GET'|'PUT'|'POST'|'DELETE', pathParams?: Object, queryParams?: Object, body?: Object, headers: Object? }? }
 * @return { Promise<*> }
 */
const requestByAction = (action, params = {}) => {
  const hotelId = getHotelId()
  const {
    method = 'GET',
    pathParams = {},
    queryParams = {},
    body = {},
    headers = {}
  } = params

  return new Promise((resolve, reject) => {
    const url = resolveUrlParams(action, pathParams)
    return Vue.http({
      url,
      method,
      params: {
        ...queryParams,
        apiKey: apiKeyMapping[store.state.channelId] || process.env.API_KEY
      },
      headers,
      body
    })
      .then(resolve)
      .catch((data) =>
        reject({ ...data, hotelId, request: { url, ...params } })
      )
  })
}

const securedRequest = async (action, sentryMessage, params) => {
  try {
    const response = await requestByAction(action, params)
    return response.ok ? response.data : null
  } catch (error) {
    SentryClient.captureRequestException({
      errorMessage: sentryMessage,
      errorObject: error
    })
  }
}

/**
 * @param action { string }
 * @param params { { pathParams?: Object, queryParams?: Object, headers: Object? }? }
 * @return { Promise<*> }
 */
const get = async (action, params = {}) =>
  requestByAction(action, { ...params, method: 'GET' })

/**
 * @param action { string }
 * @param params { { pathParams?: Object, queryParams?: Object, body?: Object | string, headers: Object? }? }
 * @return { Promise<*> }
 */
const post = async (action, params = {}) =>
  requestByAction(action, { ...params, method: 'POST' })

/**
 * @param action { string }
 * @param params { { pathParams?: Object, queryParams?: Object, body?: Object | string, headers: Object? }? }
 * @return { Promise<*> }
 */
const put = async (action, params = {}) =>
  requestByAction(action, { ...params, method: 'PUT' })

/**
 * @param action { string }
 * @param params { { pathParams?: Object, queryParams?: Object, body?: Object | string, headers: Object? }? }
 * @return { Promise<*> }
 */
const del = async (action, params = {}) =>
  requestByAction(action, { ...params, method: 'DELETE' })

export default {
  request,
  requestByAction,
  securedRequest,
  get,
  post,
  put,
  del
}
