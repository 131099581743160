import { rest } from 'msw'
import store from '../app/store'

const baseUrl = process.env.CRS_ENDPOINT

export const handlers = [
  rest.post(
    `${baseUrl}/reservations/:hotelId/actions/getTaxes`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          paymentCard: {
            expireDate: '11/25',
            cardHolderName: 'ME',
            transactionId: '240613172250271940',
            redirect:
              'https://staging-reservations.hotel-spider.com/00W5abcfe6dab339/checkout/afterpay'
          },
          roomStays: [
            {
              reference: '1-11',
              guestRoomId: '00W5abd08ecdf160',
              ratePlanId: '00W5abd09509b775',
              startDate: '2024-06-13',
              endDate: '2024-06-15',
              nbAdults: 2,
              nbChildren: 0,
              nbInfants: 0,
              totalPrice: 524,
              currency: 'CHF',
              isPackage: false,
              services: [
                {
                  instance: '00W5abd154952778#1-11',
                  currency: 'CHF',
                  quantity: 1,
                  totalDiscount: 0,
                  totalPrice: 80,
                  taxes: [
                    {
                      taxType: 'GST',
                      taxDescription: 'India (Country)',
                      taxPercentage: 9,
                      taxAmount: '7.20',
                      taxCurrency: 'CHF'
                    },
                    {
                      taxType: 'GST',
                      taxDescription: 'Rajastan (State)',
                      taxPercentage: 9,
                      taxAmount: '7.20',
                      taxCurrency: 'CHF'
                    }
                  ]
                },
                {
                  instance: '00W62fb4b621984b#1-11',
                  quantity: 1,
                  currency: 'CHF',
                  totalPrice: 40,
                  totalDiscount: 10,
                  taxes: [
                    {
                      taxType: 'GST',
                      taxDescription: 'India (Country)',
                      taxPercentage: 9,
                      taxAmount: '3.60',
                      taxCurrency: 'CHF'
                    },
                    {
                      taxType: 'GST',
                      taxDescription: 'Rajastan (State)',
                      taxPercentage: 9,
                      taxAmount: '3.60',
                      taxCurrency: 'CHF'
                    }
                  ]
                }
              ],
              taxes: [
                {
                  taxType: 'GST',
                  taxDescription: 'India (Country)',
                  taxPercentage: 9,
                  taxAmount: '47.16',
                  taxCurrency: 'CHF'
                },
                {
                  taxType: 'GST',
                  taxDescription: 'Rajastan (State)',
                  taxPercentage: 9,
                  taxAmount: '47.16',
                  taxCurrency: 'CHF'
                }
              ]
            }
          ],
          sessionId: 'dFYXgVHu1crj0XyQ',
          guestInfo: {
            givenName: 'Hotel-Spider',
            surname: 'Tester',
            phone: '0796885203',
            email: 'yannick.blondeau@hotel-spider.com',
            specialRequest: 'This is my special request',
            businessTravel: true,
            address: {
              addressLine: '',
              postalCode: '',
              city: '',
              state: '',
              country: 'CH'
            }
          },
          requestType: 'Book',
          language: 'en'
        })
      )
    }
  )
  //   // rest.get(`${baseUrl}/config/:hotelId`, (req, res, ctx) => {
  //   //   return res(
  //   //     ctx.status(200),
  //   //     ctx.json({
  //   //       'defaultSorting': 'pricesDown',
  //   //       'trackers': '{"MyHotelShop":{"hid":"ABC123DEF"},"GoogleTagManager":{"accountId":"GTM-NN4QCTX"},"CompareMe123":{"hid":"123456"},"GoogleAnalytics":{"trackingCode":"UA-137592763-1","trackingCodev4":"G-999999999"},"GoogleGlobalSiteTag":{"tagId":"tagid123","routeId":"tagroute"}}',
  //   //       'addressRequired': false,
  //   //       'minAdults': 1,
  //   //       'maxAdults': 6,
  //   //       'minInfants': 0,
  //   //       'maxInfants': 1,
  //   //       'minChildren': 0,
  //   //       'maxChildren': 5,
  //   //       'test': true,
  //   //       'tenantId': 3,
  //   //       'whitelabelId': 1,
  //   //       'currency': 'USD',
  //   //       'invoicingCurrency': 'CHF',
  //   //       'lastModifyDateTime': '2022-04-27T11:53:22+02:00'
  //   //     })
  //   //   );
  //   // }),
  //   rest.post(`${baseUrl}/reservations/:hotelId/actions/book`, (req, res, ctx) => {
  //     if (store.getters.isCCAvenuePayment) {
  //       return res(
  //         ctx.status(200),
  //         ctx.json({
  //           'status': 'Book',
  //           'reservationId': 'SB4-576C06F345',
  //           'url': 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=815978&encRequest=9c37f4b0aebac3053d36a99c310929c91c32f82a92da3c0d9a8db7ee6e85de71ba2d51eb2c62ccaf9ab2bf25f3b6a6ec6ceb66f5334c6c43848fd891a7618caf9ed9bddfd2bd9f583a19f0a39d5bb0539a8c58c376ddae8176f929d3a81374bdf800d9edd16bc451db1375c869869747b9ff8b1ca89babafcf58a951379385b85fe391581db74edc0202fc4fab7c37b8a70e9e351d1b8bbe2f9abe9e15bc8ad40944b529bd9929126547894d61a97b2a8f505254f295433333ed6a759a395b8176cb9d7d2d1ffe6b58cddc59f767ca304bc643d1cccbcd2991e00806bba3d37636fec77f9f31e537c75deaead476b08eeca33a684b9509820015767271d69603d1e2363e5687e21ac121aa4e006479c6d9c6d562b0d960d9575de6e47fb3227f76295c896885dbe4a1967de1360b576679a9e2e68d7f719070b38d2cc4c7568f39c5a412314bf26d52ffb346a638c24cce22b2669e003c39c4aa4994fcf7ad5ad1697bdee739900344bfec8d481cd634a70690767bed8004160dd8bcf637fc1f509ef8eddd3380123347059429d5c2b1d42b0712a65f399834ac3c314374b2a1d4478c9cd168ff7d495ac27419dc4df981d9db36918eefe2706f89071917d117756be73ee796281bb1dac8d8bb17215b29ca6f53c3618eedba6aefbf783e4894&access_code=AVAB75JC81CA93BAAC',
  //           'language': 'en',
  //           'intent': 'pay'
  //         })
  //       );
  //     }
  //     return req.passthrough();  // pass through to the real server
  //   }),
]
