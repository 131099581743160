export const CHANNEL_ID_DEFAULT = 387;
export const CHANNEL_ID_DIGIBOOKING = 441;
export const CHANNEL_ID_TRIP_ADVISOR = 304;
export const CHANNEL_ID_GOOGLE_HOTEL_ADS = 399;
export const CHANNEL_ID_TRIVAGO = 344;
export const CHANNEL_ID_KAYAK = 400;
export const CHANNEL_ID_SPIDERMETA = 443;
export const CHANNEL_ID_KINDERHOTELINFO = 452;
export const CHANNEL_ID_HUNDEHOTELINFO = 453;
export const CHANNEL_ID_HOTELSSALZBURGINFO = 454;
export const CHANNEL_ID_SUPERIORHOTELSINFO = 455;
export const CHANNEL_ID_FERIENPENSIONENINFO = 456;
export const CHANNEL_ID_PISTENHOTELSINFO = 457;
export const CHANNEL_ID_DIALOGSHIFT = 472;

export const TRIP_ADVISOR_PARTNER_NAME = process.env.TRIP_ADVISOR_PARTNER_NAME;
export const TRIP_ADVISOR_PIXEL_ID = process.env.TRIP_ADVISOR_PIXEL_ID;
