import { get, has } from "lodash";
import store from "../store";
import * as channels from "../system/constants/channels";

const trivagoHandle = (link) => {
  const ref = get(link.query, "trv_reference"),
    locale = get(link.query, "trv_locale"),
    cpa = has(link.query, "trv_CPA");
  if (!ref) {
    return;
  }

  store.commit("SET_CHANNEL_PARAMETERS", {
    [channels.CHANNEL_ID_TRIVAGO]: {
      trv_reference: ref,
      trv_locale: locale,
      trv_CPA: cpa,
    },
  });
};

const channelsCallbacks = {
  [channels.CHANNEL_ID_DEFAULT]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_DIGIBOOKING]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_TRIP_ADVISOR]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_GOOGLE_HOTEL_ADS]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_TRIVAGO]: {
    handle: trivagoHandle,
  },
  [channels.CHANNEL_ID_KAYAK]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_SPIDERMETA]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_KINDERHOTELINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_HUNDEHOTELINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_HOTELSSALZBURGINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_SUPERIORHOTELSINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_FERIENPENSIONENINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_PISTENHOTELSINFO]: {
    handle: () => {},
  },
  [channels.CHANNEL_ID_DIALOGSHIFT]: {
    handle: () => {},
  },
};

export const isOwnChannel = (channelId) =>
  channelId === channels.CHANNEL_ID_DEFAULT;

export const handleChannelCallback = (channelId, link) => {
  const channel = channelsCallbacks[channelId];
  if (channel === undefined) {
    return;
  }

  channel.handle(link);
};
