import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=2ee30f13&scoped=true"
import script from "./LanguageSelect.vue?vue&type=script&setup=true&lang=js"
export * from "./LanguageSelect.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&id=2ee30f13&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0__lodash@4.17.21_prettier@3.1.1_pug@3.0.2_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee30f13",
  null
  
)

export default component.exports