import router from "@/app/router";

export default {
  actions: {
    replaceRoute(_state, { name, params = {} }) {
      if (router.currentRoute.name !== name) {
        router.replace({
          name,
          params: {
            ...params,
          },
        });
      }
    },
  },
};
