var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rooms-container mt-1"},[_c('transition-group',{staticClass:"rooms-container__list",attrs:{"tag":"div","name":"list"}},[(_vm.loading)?_vm._l((4),function(index){return _c('skeleton-room-item',{key:index})}):[(!_vm.sortedRooms.length)?_c('v-alert',{key:"error",staticClass:"mt-5",attrs:{"border":"top","colored-border":"","color":"primary","type":"info","elevation":"2"}},[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t(
              "Search#Results#NoResults Can't find rooms matching this selection"
            ))+"\n        ")]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.contactDetails)}})]):[(_vm.showHotelPackages)?_vm._l((_vm.hotelPackagesAmongResults),function(item){return _c('packages-list-item',{key:item.id,attrs:{"item":item}})}):_vm._e(),_vm._v(" "),(
            _vm.showHotelPackages &&
            _vm.hasHotelPackagesNotAmongResults &&
            _vm.hasHotelPublicPackages
          )?_c('router-link',{key:"showMorePackages",staticClass:"text-center d-flex justify-center",attrs:{"to":{ name: 'packages' }}},[_c('v-sheet',{staticClass:"my-6"},[_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","ripple":false,"outlined":"","tile":"","x-large":""}},[_vm._v("\n              "+_vm._s(_vm.hasHotelPackagesAmongResults
                  ? _vm.$t('Search#Results#AllPackages See all packages')
                  : _vm.$t('Search#Results#AllPackages See special offers'))+"\n            ")])],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.sortedRooms),function(item,i){return _c('rooms-list-item',{key:item.id,attrs:{"item":item,"is-expanded":i === _vm.expandedItemIndex,"index":i},on:{"expand":function($event){return _vm.collapseRatePlans($event)}}})}),_vm._v(" "),_c('v-sheet',{key:"all-loaded"},[_c('p',{staticClass:"text-all_loaded text--secondary"},[_vm._v("\n            "+_vm._s(_vm.$t('Search#Results#AllResults All results loaded'))+"\n          ")])])]]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }