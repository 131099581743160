/* eslint-disable quotes */
const JSON_STUB = {
  oldLink: 'http://jsonstub.com/',
  content: 'application/json',
  userKey: '38b81223-6b68-404a-8a40-335d6af7a2c1',
  projectKey: 'e4193d51-a754-4978-9fc0-0c1a78a35732'
}
const DATE_FORMAT = ['YYYY-M-D', 'YYYY-MM-DD']

const DATE_TIME_FORMAT_DAY_LONG = {
  weekday: 'long',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}
const DATE_TIME_FORMAT_DAY_SHORT = {
  ...DATE_TIME_FORMAT_DAY_LONG,
  ...{ weekday: 'short' }
}
const DATE_TIME_FORMAT_DAY_MONTH_LONG = {
  ...DATE_TIME_FORMAT_DAY_LONG,
  ...{ month: 'long' }
}
const NOT_FOUND_REDIRECT_TIMEOUT = 5000
const PRICE_ADDITIONAL_LENGTH = 1
const PRODUCT_DESC_MAX_LEN = 200
const RATE_DESC_MAX_LEN = 100
const SESSION_EXPIRATION_TIMEOUT = 900000 // 15m
const TRANSITION_TIME = 300
const TRANSITION_TIME_LONG = TRANSITION_TIME * 1.4

// TAX SERVICE COUNTRIES
export const TAX_SERVICE_COUNTRIES = ['IN', 'BD']

// OTA to Datatrans payment method mapping
const PAYMENT_METHOD_CODES = {
  AX: 'AMX',
  BC: 'BAC',
  BL: 'CBL',
  CB: 'CBL',
  DN: 'DIN',
  DS: 'DIS',
  EC: 'ECA',
  JC: 'JCB',
  MA: 'MAU',
  MC: 'ECA',
  CU: 'CUP',
  TP: 'UAP',
  VE: 'VIS',
  VI: 'VIS'
}

const LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ar: 'العربية',
  bn: 'বেঙ্গলি; বাংলা',
  cs: 'Čeština',
  da: 'Dansk',
  el: 'Ελληνική, Νέα',
  gu: 'ગુજરાતી',
  he: 'עברית (מודרנית)',
  hi: 'हिंदी',
  hr: 'hrvatski',
  hu: 'Magyar',
  ja: '日本語',
  ko: '한국어',
  mr: 'मराठी',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  ro: 'română',
  ru: 'русский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sv: 'Svenska',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  th: 'ไทย',
  zh: '中文'
}

const CURRENCIES = {
  preferred: ['EUR', 'USD', 'GBP', 'CHF'],
  others: [
    'AED',
    'ARS',
    'AUD',
    'BHD',
    'BRL',
    'CAD',
    'CLP',
    'CNY',
    'COP',
    'CZK',
    'DKK',
    'HUF',
    'ILS',
    'INR',
    'JOD',
    'JPY',
    'KRW',
    'KWD',
    'MXN',
    'NOK',
    'OMR',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'SAR',
    'SEK',
    'THB',
    'ZAR'
  ]
}

const PRESET_BASE = {
  bgUrl: '',
  logoUrl: '',
  colorBg: { hex: '#FFFFFF', a: 1 },
  colorBtn: { hex: '#FFFFFF', a: 1 },
  colorBtnBg: { hex: '#2B2A2A', a: 1 },
  colorLink: { hex: '#2B2A2A', a: 1 },
  colorPrimary: { hex: '#2B2A2A', a: 1 },
  fontFamilyBtn: '"Open Sans HSB", sans-serif',
  fontFamilyPrimary: '"Open Sans HSB", sans-serif',
  fontFamilyTitle: '"Open Sans HSB", sans-serif',
  fontSizeBtn: 'medium',
  fontSizePrimary: 'medium',
  fontSizeTitle: 'medium'
}

const COLOR_SCHEME = {
  light: {
    ...PRESET_BASE,
    isDarkMode: false,
    colorText: { hex: '#2B2A2A', a: 1 }
  },
  dark: {
    ...PRESET_BASE,
    isDarkMode: true,
    colorText: { hex: '#FFFFFF', a: 1 }
  }
}

const ERROR_CODES = {
  5025: "App#Error#No#Availabilities Unfortunately, there's no more availabilities left. Please try again later",
  5050: '',
  5601: 'App#Error#Reservation already cancelled',
  5610: 'App#Error Cancellation failed, server error. Please check that the reservation ID and email address are correct.',
  5611: 'App#Error Failed loading reservation data. Please try again.',
  5615: 'App#Error#Expired Cannot modify expired reservations',
  5901: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5902: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5903: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5904: 'App#Error Unable to process this transaction. Your credit card has not been charged! Please try again.',
  5908: 'App#Payment Cannot charge credit card',
  5912: 'App#Payment Cannot update reservation after successful payment',
  5915: 'App#Error#Payment Transaction already processed',
  BOOKING_FAILED: 'App#Error Booking failed. Please try again.'
}

const ROOM_AMENITY_TYPE = {
  // https://tourisoft.atlassian.net/wiki/spaces/HOT/pages/4325585/Room+Amenity+Type+RMA
  // code: 'icon-class' // description
  // to get 'icon-class' see 'src/app/static/css/ui/_icons.scss'

  1: '', //Adjoining rooms
  2: 'air-conditioner', //Air conditioning
  3: 'alarm-clock', //Alarm clock
  4: 'news', //All news channel
  5: 'radio', //	AM/FM radio
  6: 'baby-bed', //Baby listening device
  7: 'balcony', //	Balcony/Lanai/Terrace
  8: '', //	Barbeque grills
  9: 'hot-tub-person', //	Bath tub with spray jets
  10: '', //	Bathrobe
  11: 'shampoo', //	Bathroom amenities
  12: '', //	Bathroom telephone
  13: '', //	Bathtub
  14: '', //	Bathtub only
  15: '', //Bathtub/shower combination
  16: '', //	Bidet
  17: '', //	Bottled water
  18: '', //	Cable television
  19: 'mug-hot', //	Coffee/Tea maker
  20: 'tv', // Color television
  21: '', // Computer
  22: '', //	Connecting rooms
  23: '', //	Converters/ Voltage adaptors
  24: '', // Copier
  25: '', // Cordless phone
  26: '', // Cribs
  27: '', // Data port
  28: '', // Desk
  29: 'lamp-desk', //Desk with lamp
  30: '', //	Dining guide
  31: 'phone', //	Direct dial phone number
  32: '', //Dishwasher
  33: 'bed-front', //Double beds
  34: 'outlet', //	Dual voltage outlet
  35: '', //	Electrical current voltage
  36: '', //	Ergonomic chair
  37: '', //	Extended phone cord
  38: '', //	Fax machine
  39: '', //	Fire alarm
  40: '', //	Fire alarm with light
  41: '', //	Fireplace
  42: '', //	Free toll free calls
  43: '', //	Free calls
  44: '', //	Free credit card access calls
  45: '', //	Free local calls
  46: 'film', //	Free movies/video
  47: '', //	Full kitchen
  48: 'mini-bar', //	Grab bars in bathroom
  49: '', //	Grecian tub
  50: 'hair-dryer', //	Hairdryer
  51: 'free-wi-fi', //	High speed internet connection
  52: '', //	Interactive web TV
  53: '', //	International direct dialing
  54: 'free-wi-fi', //	Internet access
  55: '', //	Iron
  56: '', //	Ironing board
  57: '', //	Whirpool
  58: 'bed-front', //	King bed
  59: '', //	Kitchen
  60: '', //	Kitchen supplies
  61: '', //	Kitchenette
  62: '', //	Knock light
  63: '', //	Laptop
  64: '', //	Large desk
  65: '', //	Large work area
  66: '', //	Laundry basket/clothes hamper
  67: '', //	Loft
  68: 'microwave', //	Microwave
  69: 'mini-bar', //	Minibar
  70: '', //	Modem
  71: '', //	Modem jack
  72: 'phone', //	Multi-line phone
  73: 'newspaper', //	Newspaper
  74: 'smoking-ban', //	Non-smoking
  75: '', //	Notepads
  76: '', //	Office supplies
  77: 'oven', //	Oven
  78: '', //	Pay per view movies on TV
  79: '', //	Pens
  80: '', //	Phone in bathroom
  81: '', //	Plates and bowls
  82: '', //	Pots and pans
  83: '', //	Prayer mats
  84: '', //	Printer
  85: 'bath', //	Private bathroom
  86: 'bed-front', //	Queen bed
  87: '', //	Recliner
  88: '', //	Refrigerator
  89: '', //	Refrigerator with ice maker
  90: '', //	Remote control television
  91: '', //	Rollaway bed
  92: 'vault', //	Safe
  93: '', //	Scanner
  94: 'clothes-hanger', //	Separate closet
  95: '', //	Separate modem line available
  96: '', //	Shoe polisher
  97: 'shower', //	Shower only
  98: '', //	Silverware/utensils
  99: '', //	Sitting area
  100: '', //	Smoke detectors
  101: 'smoking', //	Smoking
  102: '', //	Sofa bed
  103: '', //	Speaker phone
  104: '', //	Stereo
  105: '', //	Stove
  106: '', //	Tape recorder
  107: '', //	Telephone
  108: '', //	Telephone for hearing impaired
  109: '', //	Telephones with message light
  110: '', //	Toaster oven
  111: '', //	Trouser/Pant press
  112: '', //	Turn down service
  113: 'bed-front', //	Twin bed
  114: '', //	Vaulted ceilings
  115: 'film', //	VCR movies
  116: '', //	VCR player
  117: '', //	Video games
  118: '', //	Voice mail
  119: '', //	Wake-up calls
  120: '', //	Water closet
  121: '', //	Water purification system
  122: '', //	Wet bar
  123: 'wifi', //	Wireless internet connection
  124: 'keyboard', //	Wireless keyboard
  125: '', //	Adaptor available for telephone PC use
  126: '', //	Air conditioning individually controlled in room
  127: '', //	Bathtub &whirlpool separate
  128: '', //	Telephone with data ports
  129: '', //	CD  player
  130: '', //	Complimentary local calls time limit
  131: 'person', //	Extra person charge for rollaway use
  132: '', //	Down/feather pillows
  133: 'outlet', //	Desk with electrical outlet
  134: '', //	ESPN available
  135: '', //	Foam pillows
  136: '', //	HBO available
  137: '', //	High ceilings
  138: 'sparkles', //	Marble bathroom
  139: '', //	List of movie channels available
  140: 'pets-friendly', //	Pets allowed
  141: '', //	Oversized bathtub
  142: '', //	Shower
  143: '', //	Sink in-room
  144: '', //	Soundproofed room
  145: '', //	Storage space
  146: '', //	Tables and chairs
  147: 'phone', //	Two-line phone
  148: '', //	Walk-in closet
  149: '', //	Washer/dryer
  150: '', //	Weight scale
  151: '', //	Welcome gift
  152: 'outlet', //	Spare electrical outlet available at desk
  153: 'paw', //	Non-refundable charge for pets
  154: 'paw', //	Refundable deposit for pets
  155: '', //	Separate tub and shower
  156: '', //	Entrance type to guest room
  157: '', //	Ceiling fan
  158: '', //	CNN available
  159: '', //	Electrical adaptors available
  160: 'breakfast', //	Buffet breakfast
  161: '', //	Accessible room
  162: '', //	Closets in room
  163: 'disc-drive', //	DVD player
  164: '', //	Mini-refrigerator
  165: '', //	Separate line billing for multi-line phone
  166: '', //	Self-controlled heating/cooling system
  167: '', //	Toaster
  168: '', //	Analog data port
  169: '', //	Collect calls
  170: '', //	International calls
  171: '', //	Carrier access
  172: '', //	Interstate calls
  173: '', //	Intrastate calls
  174: '', //	Local calls
  175: '', //	Long distance calls
  176: '', //	Operator-assisted calls
  177: '', //	Credit card access calls
  178: '', //	Calling card calls
  179: '', //	Toll free calls
  180: '', //	Universal AC/DC adaptors
  181: '', //	Bathtub seat
  182: '', //	Canopy/poster bed
  183: '', //	Cups/glassware
  184: '', //	Entertainment center
  185: 'for-family', //	Family/oversized room
  186: '', //	Hypoallergenic bed
  187: '', //	Hypoallergenic pillows
  188: 'lamp-desk', //	Lamp
  189: 'breakfast', //	Meal included - breakfast
  190: 'breakfast', //	Meal included - continental breakfast
  191: '', //	Meal included - dinner
  192: '', //	Meal included - lunch
  193: 'shampoo', //	Shared bathroom
  194: '', //	Telephone TDD/Textphone
  195: '', //	Water bed
  196: '', //	Extra adult charge
  197: '', //	Extra child charge
  198: '', //	Extra child charge for rollaway use
  199: 'breakfast', //	Meal included:  full American breakfast
  200: '', //	Futon
  201: '', // Murphy bed
  202: '', // Tatami mats
  203: '', // Single bed
  204: '', // Annex room
  205: '', // Free newspaper
  206: '', // Honeymoon suites
  207: 'free-wi-fi', // Complimentary high speed internet in room
  208: '', // Maid service
  209: '', // PC hook-up in room
  210: 'satellite-dish', // Satellite television
  211: '', //	VIP rooms
  212: '', //	Cell phone recharger
  213: '', //	DVR player
  214: '', //	iPod docking station
  215: '', //	Media center
  216: '', //	Plug & play panel
  217: 'satellite-dish', //	Satellite radio
  218: '', //	Video on demand
  219: '', //	Exterior corridors
  220: '', //	Gulf view
  221: '', // Accessible room,
  222: '', // Interior corridors,
  223: 'mountains', // Mountain view,
  224: '', // Ocean view,
  225: '', // High speed internet access fee,
  226: '', // High speed wireless,
  227: '', // Premium movie channels,
  228: '', // Slippers,
  229: '', // First nighters' kit,
  230: '', // Chair provided with desk,
  231: '', // Pillow top mattress,
  232: '', // Feather bed,
  233: '', // Duvet,
  234: '', // Luxury linen type,
  235: '', // International channels,
  236: '', // Pantry,
  237: '', // Dish-cleaning supplies,
  238: '', // Double vanity,
  239: '', // Lighted makeup mirror,
  240: '', // Upgraded bathroom amenities,
  241: 'cassete-vhs', // VCR player available at front desk,
  242: 'hot-tub-person', // Instant hot water,
  243: '', // Outdoor space,
  244: '', // Hinoki tub,
  245: '', // Private pool,
  246: '', // High Definition (HD) Flat Panel Television  - 32 inches or greater ,
  247: '', // Room windows open,
  248: '', // Bedding type unknown or unspecified,
  249: '', // Full bed,
  250: '', // Round bed,
  251: 'tv', // TV,
  252: '', // Child rollaway,
  253: '', // DVD player available at front desk,
  254: '', // Video game player:                                      ,
  255: '', // Video game player available at front desk,
  256: '', // Dining room seats,
  257: '', // Full size mirror,
  258: '', // Mobile/cellular phones,
  259: 'film', // Movies,
  260: '', // Multiple closets,
  261: '', // Plates/glassware,
  262: '', // Safe large enough to accommodate a laptop,
  263: '', // Bed linen thread count,
  264: '', // Blackout curtain,
  265: '', // Bluray player,
  266: '', // Device with mp3,
  267: '', // No adult channels or adult channel lock,
  268: '', // Non-allergenic room,
  269: '', // Pillow type,
  270: '', // Seating area with sofa/chair,
  271: '', // Separate toilet area,
  272: '', // Web enabled,
  273: 'tv', // Widescreen TV,
  274: '', // Other data connection,
  275: '', // Phoneline billed separately,
  276: '', // Separate tub or shower,
  277: 'gamepad', // Video games,
  278: '', // Roof ventilator,
  279: '', // Children's playpen,
  280: 'water-ladder', // Plunge pool",
  5017: 'window-frame-open'
}

export {
  CURRENCIES,
  DATE_TIME_FORMAT_DAY_LONG,
  DATE_TIME_FORMAT_DAY_SHORT,
  DATE_TIME_FORMAT_DAY_MONTH_LONG,
  ERROR_CODES,
  JSON_STUB,
  LANGUAGES,
  DATE_FORMAT,
  NOT_FOUND_REDIRECT_TIMEOUT,
  PAYMENT_METHOD_CODES,
  PRICE_ADDITIONAL_LENGTH,
  PRODUCT_DESC_MAX_LEN,
  RATE_DESC_MAX_LEN,
  ROOM_AMENITY_TYPE,
  SESSION_EXPIRATION_TIMEOUT,
  COLOR_SCHEME,
  TRANSITION_TIME_LONG
}
