import { i18n } from "../../vue-config/i18n";
import { getSecureFieldsOptions } from "@/app/system/plugins/datatrans";
import SentryClient from "@/app/system/SentryClient";
import { useVuetify } from "@/app/composables/useVuetify";

import { DATATRANS_LIB_URL } from "@/app/system/paymentGatewayHelper";
import { getErrorMessageWithTimestamp, showAlertPopup } from "@/app/system/helper";
import { mapGetters, mapMutations } from "vuex";
import { PAYMENT_METHOD_CODES } from '@/app/constants';

export default {
  setup() {
    const vuetify = useVuetify();

    return {
      isDarkTheme: vuetify.theme.dark
    };
  },
  computed: {
    ...mapGetters(["bookingRequest", "hotelContacts", "acceptedPaymentCards"]),
  },
  methods: {
    ...mapMutations(["SET_RESERVATION_LOADING"]),
    initSecureFields(fields) {
      this.secureFields = new window.SecureFields();
      this.secureFields.initTokenize(
        process.env.DATATRANS_MERCHANT_ID,
        fields,
        {
          ...getSecureFieldsOptions(this.isDarkTheme),
          ...{ paymentMethods: this.acceptedPaymentCards.map(card => PAYMENT_METHOD_CODES[card.code] || '') }
        }
      );
      this.bindSecureFieldsEvents();
    },
    bindSecureFieldsEvents() {
      this.secureFields.on("ready", () => {
        this.$bus.$emit("secureFields:ready");
      });

      this.secureFields.on("change", (data) => {
        data && this.$bus.$emit("secureFields:change", data);
      });

      this.secureFields.on("validate", (data) => {
        this.$bus.$emit("secureFields:validate", data);
      });

      this.secureFields.on("success", (data) => {
        this.$bus.$emit("secureFields:success", data);
      });

      this.secureFields.on("error", (data) => {
        this.$bus.$emit("secureFields:error", data);
        this.handleSecureFieldsError(data);
      });
    },
    handleSecureFieldsError(data) {
      SentryClient.captureExceptionWithScope(
        "Datatrans config has been failed",
        {
          extraKey: "Response data",
          extraData: { data },
          tags: { type: "datatrans_config_failed" },
        },
        "fatal"
      );

      const errorMsg = getErrorMessageWithTimestamp({
        errorDescription: `${i18n.t(
          "Checkout#DatatransPayment#Error Payment processing error"
        )}: ${data.error}`,
        sessionId: this.bookingRequest.sessionId,
        i18nParams: this.hotelContacts,
      });

      showAlertPopup(errorMsg).then(() => {
        this.SET_RESERVATION_LOADING(false);
      });
    },
    threeDsChallengeRedirect(url) {
      window.open(url, "_self");
    },
    async loadSecureFields(fields) {
      await this.$loadScript(`${DATATRANS_LIB_URL}`)
      this.initSecureFields(fields)
    },
  },
  beforeDestroy() {
    this.secureFields?.destroy();
  },
};
