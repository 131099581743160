import * as Sentry from "@sentry/vue";

let SentryClient = {
  ...Sentry,
  /**
   * @param {Error|string} exception
   * @param {{extraKey: string, extraData: Object, tags: Object}} extra
   * @param {Severity?} level
   */
  captureExceptionWithScope(
    exception,
    { extraKey, extraData, tags = {} } = {},
    level = "error",
  ) {
    Sentry.withScope((scope) => {
      scope.setTags(tags);
      scope.setExtra(extraKey, extraData);
      scope.setLevel(level);
      Sentry.captureException(
        typeof exception === "string" ? new Error(exception) : exception,
      );
    });
  },
  captureRequestException({
    errorMessage = "",
    errorObject = {},
    extraData = {},
    type = "request_failed",
  }) {
    this.captureExceptionWithScope(
      errorMessage,
      {
        extraKey: "ResponseAndExtra",
        extraData: { errorObject, ...extraData },
        tags: {
          type,
          ...(errorObject?.body?.internalErrorCode && {
            internalErrorCode: errorObject.body.internalErrorCode,
          }),
        },
      },
      "fatal",
    );
  },
};

if (process.env.SENTRY_ENABLED !== "true") {
  SentryClient = {
    captureExceptionWithScope() {
      /** Empty body for Dev environment. */
    },
    captureRequestException() {
      /** Empty body for Dev environment. */
    },
  };
}

export default SentryClient;
